import React from "react"
import "./kidsProgram.scss"

const KidsProgram = () => {
  return (
    <section>
      <div className="section-container about-content">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div
                className="row"
                style={{ marginBottom: "1rem", marginTop: "3rem" }}
              >
                <div className="col-md-6">
                  <h4 className="primary-text">How it works</h4>
                  <p>Class every saturday from 9am to 11am in our Accra and Kumasi hubs.</p>
                  <p>In-person and live online sessions options</p>
                  <ul>
                    <li>
                      <p>Saturdays 9am to 11am</p>
                      <ul>
                        <li>Kids come to our hub or join our live class session</li>
                        <li>Project review sessions</li>
                        <li>Fun and interactive learning experience</li>
                      </ul>
                    </li>
                    <li>Yearly apps showcase event</li>
                  </ul>
                  {/* <h4 className="primary-text">Structure</h4>
                  <p>We offer a practical and fun learning program</p>
                  <ul>
                    <li>The kids learn how to build websites</li>
                    <li>The kids also learn how to build mobile apps and Games</li>
                  </ul> */}
                </div>
                <div className="col-md-6">
                  <h4 className="primary-text">Pricing</h4>
                  <p>Fees is GHS 300 a month</p>
                  <ul>
                    <li>6 months payment - GHS 1500 instead of GHS 1800</li>
                    <li>1 year payment - GHS 3000 instead of GHS 3600</li>
                  </ul>
                  {/* <h4 className="primary-text">How it works</h4>
                  <p>Class every saturday from 9am to 11am in our Accra and Kumasi hubs.</p>
                  <p>In-person and live online sessions options</p>
                  <ul>
                    <li>
                      <p>Saturdays 9am to 11am</p>
                      <ul>
                        <li>Kids come to our hub or join our live class session</li>
                        <li>Project review sessions</li>
                        <li>Fun and interactive learning experience</li>
                      </ul>
                    </li>
                    <li>Yearly apps showcase event</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-6">
                  <h4 className="primary-text">Age Group and Requirements</h4>
                  <p>The program is open to kids from 7 years to 14 years.</p>
                  <ul>
                    <li>Must be able to read and write</li>
                    <li>No prior coding skills required</li>
                    <li>Must have a laptop</li>
                    <li>Stable internet connection is required for live sessions</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h4 className="primary-text">Benefits of the program</h4>
                  <ul>
                    <li>Demo day events to showcase webites and apps.</li>
                    <li>Kids developer conference</li>
                    <li>Kids create and design exciting new products</li>
                    <li>We help the kids build leadership in a technological future</li>
                  </ul>
                </div>
                {/* <div className="col-md-6">
                  <h4 className="primary-text">Schedules</h4>
                  <p>Three live sessions per week.</p>
                  <ul>
                    <li>Two live class sessions</li>
                    <li>One live one-on-one projects review with teacher</li>
                    <li>One hour duration per session</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div className="row" style={{ marginBottom: "1rem" }}>
                <div className="col-md-6">
                  {/* <h4 className="primary-text">Pricing</h4>
                  <p>Fees is GHS 300 a month</p>
                  <ul>
                    <li>6 months payment - GHS 1500 instead of GHS 1800</li>
                    <li>1 year payment - GHS 3000 instead of GHS 3600</li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default KidsProgram
