import React from "react"
import { graphql, StaticQuery } from "gatsby"
import "./kidsGallery.scss"

const KidsGallery = () => (
  <StaticQuery
    query={graphql`
      query {
        kid1: file(relativePath: { eq: "img/kids/kids-gallery-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        kid2: file(relativePath: { eq: "img/kids/kids-gallery-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        kid3: file(relativePath: { eq: "img/kids/kids-gallery-3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        kid4: file(relativePath: { eq: "img/kids/kids-gallery-4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="container">
        <div className="row gallery-img-row">
          <div className="col-md-6">
            <img
              src={data.kid1.childImageSharp.fluid.src}
              className="img-fluid gallery-img"
            />
          </div>

          <div className="col-md-6">
            <img
              src={data.kid2.childImageSharp.fluid.src}
              className="img-fluid gallery-img"
            />
          </div>
        </div>

        <div className="row gallery-img-row">
          <div className="col-md-6">
            <img
              src={data.kid3.childImageSharp.fluid.src}
              className="img-fluid gallery-img"
            />
          </div>

          <div className="col-md-6">
            <img
              src={data.kid4.childImageSharp.fluid.src}
              className="img-fluid gallery-img"
            />
          </div>
        </div>
      </div>
    )}
  />
)

export default KidsGallery
