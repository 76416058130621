import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./kidsCourses.scss"

const KidsCourses = () => (
  <StaticQuery
    query={graphql`
      query {
        kids: file(relativePath: { eq: "img/elements/kids-artwork.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container courses-web">
          <div className="row courses-mobile__content">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-5">
                  <div className="courses-mobile__text">
                    <h2>What We Teach The Kids</h2>
                    <br />
                    <p>
                      We teach the kids the fundamentals of coding that will
                      enable them to design and build websites, apps or games
                      from scratch. We also teach them key essential soft
                      skills.
                    </p>
                    <h5>Coding skills</h5>
                    <p>
                      HTML5 and CSS, Websites, Data Structures and Algorithms,
                      Framework 7, Mobile Apps and Games.
                    </p>
                    <h5>Non-coding skills</h5>
                    <p>
                      Dream development, Problem solving, Design thinking, Apps
                      demo Presentations.
                    </p>
                    <br />
                    <p>
                      <a
                        href="https://forms.gle/6wWwpif7RazHHJrq6"
                        className="btn-custom-primary-normal"
                      >
                        Register now
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <Img
                    fluid={data.kids.childImageSharp.fluid}
                    alt="codetrain kids coding"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default KidsCourses
