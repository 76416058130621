import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./kidsStories.scss"

const KidsStories = () => (
  <StaticQuery
    query={graphql`
      query {
        stories: file(relativePath: { eq: "img/people/gerrard-demo.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container courses-web">
          <div className="row courses-web__content">
            <div className="col-lg-10 offset-lg-1 col-sm-12 col-xs-12">
              <div className="row">
                <div className="col-lg-5 order-lg-first">
                  <div className="courses-web__text">
                    <h2>Success Story</h2>
                    <br />
                    <h5>Gerald Living his Dream</h5>
                    <p>
                      During his training at Codetrain, Gerald built several
                      games and mobile apps; such as an educative game that
                      teaches people how to keep clean environments to prevent
                      the spread of infectious diseases.
                    </p>
                    <br/>
                    <p>
                      <a
                        href="https://forms.gle/6wWwpif7RazHHJrq6"
                        className="btn-custom-primary-normal"
                      >
                        Register now
                      </a>
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <Img
                    fluid={data.stories.childImageSharp.fluid}
                    alt="Young Gerrard becomes a coder"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default KidsStories
