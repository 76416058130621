import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./kidsIntro.scss"

const KidsIntro = () => (
  <StaticQuery
    query={graphql`
      query {
        winimie: file(
          relativePath: { eq: "img/elements/student profile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        engineer: file(relativePath: { eq: "img/elements/jbc-artwork.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adwoa: file(
          relativePath: { eq: "img/elements/student profile-adwoa.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div className="section-container courses-intro">
          <div className="container">
            <div className="main-section-heading">
              {/* <h3 className="text-center">Codetrain Kids Coding Program</h3> */}
              <div className="row">
                <div className="col-md-6">
                  <h3 className="text-center">Who We Are</h3>
                  <p className="text-cente">
                    Codetrain was born out of the idea that when people fulfill
                    their dreams, they are able to positively impact their
                    communities. Our mission is to empower people to rise
                    digitally by training them to become software developers and
                    global leaders; setting them up to make impact in their
                    communities.
                  </p>
                </div>
                <div className="col-md-6">
                  <h3 className="text-center">Kids Coding Program</h3>
                  <p className="text-cente">
                    Our kids coding program teaches kids the fundamentals of
                    coding while they work on practical projects; setting a
                    solid foundation for them to rise digitally. At the end of
                    each module, your kids will be able to design and create
                    mobile games or websites.
                  </p>
                </div>
              </div>
              {/* <h3 className="text-left" style={{ color: "#000" }}>
                Who we are
              </h3>
              <p className="text-center">
                Codetrain was born out of the idea that when people fulfill
                their dreams, they are able to positively impact their
                communities. Our mission is to empower people to rise digitally
                by training them to become software developers and global
                leaders; setting them up to make impact in their communities.
              </p>
              <p className="text-center">
                Our kids coding program teaches kids the fundamentals of coding
                while they work on practical projects; setting a solid
                foundation for them to rise digitally. At the end of each
                module, your kids will be able to design and create mobile games
                or websites.
              </p> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 offset-md-1 col-sm-12 col-xs-12">
              <div className="row">
                <div class="col-md-3 d-none d-sm-block">
                  {/* <Img
                    fluid={data.winimie.childImageSharp.fluid}
                    className="img-fluid"
                    alt="codetrain kids bootcamp"
                  /> */}
                </div>
                <div class="col-md-6">
                  <div class="">
                    <Img
                      fluid={data.engineer.childImageSharp.fluid}
                      className="img-fluid"
                      alt="codetrain kids bootcamp"
                    />
                  </div>
                </div>
                <div class="col-md-3 d-none d-sm-block">
                  {/* <Img
                    fluid={data.adwoa.childImageSharp.fluid}
                    className="img-fluid"
                    alt="codetrain kids bootcamp"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <p className="text-center">
            <a
              href="https://forms.gle/6wWwpif7RazHHJrq6"
              className="btn-custom-primary-normal"
            >
              Register now
            </a>
          </p>
        </div>
      )
    }}
  />
)

export default KidsIntro
