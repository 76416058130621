import React from "react"
import { Title, Meta } from "react-head"
import MainLayout from "../components/MainLayout"
import KidsIntro from "../components/kids/kids-intro/KidsIntro"
import KidsCourses from "../components/kids/kids-courses/KidsCourses"
import KidsProgram from "../components/kids/kids-program-details/kidsProgram"
import KidsStories from "../components/kids/kids-success-stories/KidsStories"
import KidsGallery from "../components/kids/kids-gallery/KidsGallery"

const kids = () => {
  return (
    <MainLayout>
      <Title>
        Codetrain Africa | Kids Coding Bootcamp - Kids learn how to code games
        and apps
      </Title>
      <Meta
        name="description"
        content="A coding bootcamp that teaches kids the fundamentals of 
        coding while they work on practical projects; setting a solid 
        foundation for them to rise digitally."
      />
      <KidsIntro />
      <KidsCourses />
      <KidsProgram />
      <KidsStories />
      <KidsGallery />
    </MainLayout>
  )
}

export default kids
